section {
    position: relative;
    width: 100%;
    float: left;
}


@media (min-width: 1200px) {
    .containerxx {
        max-width: 1300px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: initial;
    }
}


.containerxx {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}



.divide_why_and_how {
    width: 100%;
    float: left;
    position: relative;
}

.why_trade {
    width: 30%;
    height: 100%;
    float: left;
    padding: var(--box-gap);
    padding-left: 0;
}


.common_heading {
    position: relative;
    width: 100%;
    float: left;
    margin-top: 30px;
    margin-bottom: 15px;
}

.divide_why_and_how .headline_text {
    width: 100%;
}

.headline_text {
    width: 86%;
    float: left;
    position: relative;
    padding-top: 10px;
}


.divide_why_and_how .headline_text:before {
    width: 100%;
}

.headline_text:before {
    position: absolute;
    content: "";
    width: 98%;
    height: 1px;
    background: #e0e0e0;
    top: 20px;
    left: 0;
}


.headline_text h1,
.headline_text h2,
.headline_text h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: black;
    background: #f1f1f1;
    display: inline-block;
    padding-right: 25px;
    position: relative;
}

.trade_under {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f7f1f1;
    /* background-color: #fff; */
    float: left;
    border-radius: 3px;
    border-color: 1px solid #e6e6e6;
}

.why_trade .trade_under:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: #fff;
    border-top: 1px solid #e6e6e6;
    top: 0;
    left: 50%;
}

.why_icon {
    width: 50%;
    float: left;
    height: 149px;
    position: relative;
    padding: 22px;
    text-align: center;
    padding-top: 28px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.why_text {
    width: 100%;
    float: left;
    position: relative;
}



.why_text p {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 14px;
    color: black;
    line-height: 1.2;
}

.why_trade .trade_under:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background: #fff;
    border-top: 1px solid #e6e6e6;
    top: 50%;
    left: 0;
}

.how_trade {
    width: 70%;
    height: 100%;
    float: left;

}


.common_heading {
    position: relative;
    width: 100%;
    float: left;
    margin-top: 30px;
    margin-bottom: 15px;
}

.divide_why_and_how .headline_text {
    width: 100%;
}

.headline_text {
    width: 86%;
    float: left;
    position: relative;
    padding-top: 10px;
}

.headline_text:before {
    position: absolute;
    content: "";
    width: 98%;
    height: 1px;
    background: #e0e0e0;
    top: 20px;
    left: 0;
}

.headline_text h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: #343434;
    background: #f4f4f4;
    display: inline-block;
    padding-right: 25px;
    position: relative;
}


.trade_under {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: #fff; */
    background-color: #f7f1f1;
    float: left;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
}

.how_child {
    width: 25%;
    float: left;
    height: 300px;
    position: relative;
    border-right: 1px solid #e6e6e6;
    padding: 20px;
    text-align: center;
    padding-top: 32px;
}


.how_child h3 {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    float: left;
    margin-bottom: 12px;
    margin-top: 12px;
    position: relative;
    color: #000;
}

.how_child p {
    font-size: 13px;
    width: 100%;
    float: left;
    position: relative;
    color: #000;


}


.how_child a {
    position: absolute;
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: #000;
    left: 50%;
    transform: translate(-50%);
    bottom: 30px;
}



@media (max-width: 768px) {

    .why_trade,
    .how_trade {
        width: 100%;
        ;
    }
}


@media (max-width: 640px) {


    .how_child {
        width: 100%;
        height: 250px;
    }
}