/* styles.css */

/* Footer container */

.footBg {
    /* background-color: #f8f9fa;
    padding: 20px 30px;
    width: 100%; */
    /* background-color: #FF7F3E; */
    /* background-color: #42868f; */
    background: #0b1d3e;
    color: #fff;
    padding: 20px 30px;
    width: 100%;
}

/* Footer link wrapper */
.footLinkWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    justify-content: center;
}

/* Footer link section */
.footLinkWrap>div {
    flex: 1 1;
    /* Adjust width as needed */
}

/* Footer link styles */
.linkBold {
    font-weight: bold;
    margin-left: 9px;
}

.linkNormal {
    display: block;
    color: #fff;
    margin-bottom: 5px;
    padding: 6px 5px;
    text-decoration: none;
}

.text-col {
    color: #fff;
}

.text-col:hover {
    color: #f96c41;
}

.linkNormal:hover {
    color: #f29187;
    /* text-decoration: underline; */
    /* text-decoration-color: black; */
}

/* Location links */
.ftLocLink {
    color: #333;
    text-decoration: none;
}

.ftLocLink:hover {
    color: #007bff;
}

/* Vertical bar */
.vertical-bar {
    margin: 0 5px;
}

/* Next location links */
.footNxtLocWr {
    margin-top: 15px;
}

.ftNxtLink {
    color: #333;
    text-decoration: none;
    margin-right: 20px;
}

/* Trademark */
.trademark {
    margin-top: 20px;
    color: red;
}

.trademark:hover {
    color: orange;
    cursor: pointer;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
    .footLinkWrap {
        flex-direction: column;
        /* align-items: center; */
    }

    .footLinkWrap>div {
        flex: 1 1 100%;
        /* Adjust width as needed */
        margin-bottom: 20px;
    }

    .footNxtLocWr {
        flex-direction: column;
        align-items: center;
    }

    .lastBar {
        text-align: center;
    }
}


.trademark {
    margin: auto;
    padding-top: 20px;
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 9px;
    padding: 0px 5px;
}


.ft-nav {
    margin-top: 54px;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding-top: 25px;
    padding-bottom: 30px;
}

.justify-content-center {
    justify-content: center !important;
}

@media (min-width: 768px) {
    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
}

.text-center {
    text-align: center !important;
    margin-top: 10px;
    margin-bottom: 10px;
}


footer.tk23-footer .tk23-ft-nav ul li {
    display: inline-block;
    margin-right: 24px;
    position: relative;
}


footer .tk23-ft-nav ul li a {
    line-height: 35px;
}


footer.tk23-footer .tk23-ft-nav ul li:after {
    content: '';
    width: 1px;
    height: 14px;
    background-color: #8f8f8f;
    position: absolute;
    right: -13px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.tk23-ft-nav ul li {
    display: inline-block;
    margin-right: 24px;
    position: relative;
}

.tk23-ft-nav ul li:hover {
    color: #007bff;
    text-decoration: underline;
}

.col-md-8 {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
}

/* External CSS file: styles.css */

.market {
    /* width: 80%; */

    margin: 0 auto;
    /* Center-aligns the container */
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    text-align: center;
    /* Center-aligns text inside the container */
}

.market h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: rgb(255, 174, 25);
}

.market p {

    line-height: 1.3;
    font-size: 15px;
    color: #555;
    text-align: justify;
    /* Justifies text for better readability */
    margin: 0 auto;

    /* Optional: limit the width of the paragraph for better readability */
}