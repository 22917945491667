.login-page {
    display: flex;
    height: 100vh;
    width: 100%;
    background-image: url(../../public/assets/baner4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This ensures the image covers the entire container */
    background-position: center;
    /* Gradient from light orange to darker orange */
}

.login-container {
    /* width: 50%; */
    width: 100%;
    padding: 40px;
    /* background: #f7f1f1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container h1 {
    font-size: 36px;
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 5px;
    width: 100%;
    color: #333;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}



.submit-login {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-login:hover {
    background-color: orange;
    cursor: pointer;
}

.login-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    /* background: #f7f1f1; */
}

.login-image img {
    width: 100%;
    height: 100%;
}

.main-login {
    padding: 30px 50px;
}

.error {
    color: red;
    margin-top: 10px;
}

/* Container for login and image sections */
.login-page {
    display: flex;
    height: 100vh;
    width: 100%;
}

/* Container for login form */
.login-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Main login form styling */
.main-login {
    background: rgba(255, 255, 255, 0.1);
    /* Lightly colored background */
    backdrop-filter: blur(10px);
    /* Blur the background behind the form */
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    /* Soft shadow */
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* Light border */
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin-bottom: 100px;
}

/* Form group styling */
.form-group {
    margin-bottom: 5px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: black;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
    /* White text color */
}

.forgot-password {
    width: auto;
    padding: 10px;
    font-size: 16px;
    margin-top: 20px;
    background-color: #f12b2b;
    color: #010000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.otpdata {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.8);
}

.forgot-password:hover {
    background-color: rgb(154, 252, 74);
    cursor: pointer;
}

input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.8);
}

/* Submit button styling */
.submit-login {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: rgba(0, 123, 255, 0.8);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: rgba(0, 123, 255, 1);
}

/* Error message styling */
.error {
    color: red;
    margin-top: 10px;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
    .login-page {
        flex-direction: column;
    }

    .login-container {
        width: 100%;
        padding: 20px;
    }

    .login-image {
        display: none;
        /* Hide the image on small screens */
    }
}

@media (max-width: 600px) {

    .login-container,
    .login-image {
        width: 100%;
        padding: 20px;
    }

    .login-page {
        flex-direction: column;
    }

    .main-login {
        order: 2;
        /* Ensure form appears first on small screens */
    }

    .login-image {
        order: 1;
    }
}

.login-image img {
    /* object-fit: cover; */
    width: 100%;
}

.login-image {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 0px !important;
}

/* ==================== Privacy Section ======================= */

.terms-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
}

.terms-section h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.privacy-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
}

.privacy-section h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.privacy-section p {
    font-size: 1em;
    color: #666;
    text-align: left;
    margin-bottom: 40px;
}

.terms-section p {
    font-size: 1em;
    color: #666;
    text-align: left;
    margin-bottom: 40px;
}

.privacy-section p {
    font-size: 1em;
    color: #666;
    text-align: left;
    margin-bottom: 40px;
}

.privacy-section h2 {
    font-size: 1.5em;
    margin-top: 30px;
    color: #333;
}

.privacy-section h3 {
    font-size: 1.2em;
    margin-top: 20px;
    color: #555;
}

.privacy-section p,
.privacy-section ul {
    line-height: 1.6;
    color: #555;
}

.privacy-section ul {
    list-style-type: disc;
    margin-left: 20px;
}

.privacy-section ul li {
    margin-bottom: 10px;
}

.privacy-section a {
    color: #007bff;
    text-decoration: none;
}

.privacy-section a:hover {
    text-decoration: underline;
}


/* =============================== AboutUs Css ==================================== */


.about-us-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

.image-container {
    flex: 1 1 300px;
    max-width: 500px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
}

.image-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.text-container {
    flex: 2 1 500px;
    max-width: 800px;
    padding: 20px;
    background-color: #fff;

}

.text-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.text-container h2 {
    margin-top: 20px;
}

.text-container p,
.text-container ul {
    margin: 10px 0;
}

.text-container ul {
    list-style-type: disc;
    padding-left: 40px;
}

.text-container ul li {
    margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-us-container {
        flex-direction: column;
    }

    .image-container,
    .text-container {
        max-width: 100%;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .text-container h1 {
        font-size: 1.5em;
    }

    .text-container h2 {
        font-size: 1.2em;
    }
}

.containerss {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
}

.half {
    flex: 1;
    padding: 20px;
}

@media (max-width: 768px) {
    .half {
        flex: 100%;
    }
}

.why-containss {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #fff;
}

.why-containss h2 {
    padding: 10px 5px;
}

.why-containss ul li {
    padding: 10px 3px;
}