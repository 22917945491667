.iph-banner {
    background-image: url(../../public/assets/usa-flag2.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-d {
    background-image: url(../../public/assets/uaeco.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}


.iph-banner-aus {
    background-image: url(../../public/assets/aus-flag1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-ger {
    background-image: url(../../public/assets/ger4.webp);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-chi {
    background-image: url(../../public/assets/china1.avif);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-uka {
    background-image: url(../../public/assets/uk2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-can {
    background-image: url(../../public/assets/canda.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-sau {
    background-image: url(../../public/assets/saudi1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-oman {
    background-image: url(../../public/assets/oman.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-singapore {
    background-image: url(../../public/assets/singapore.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-netherland {
    background-image: url(../../public/assets/netherland.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}

.iph-banner-flagall {
    background-image: url(../../public/assets/flagall.avif);
    background-repeat: no-repeat;
    background-size: cover;
    /* This will make the background image cover the entire container */
    background-position: center;
    display: flex;
    /* Enables flexbox for centering content */
    align-items: center;
    /* Vertically centers content */
    justify-content: center;
    /* Horizontally centers content */

}


.ffos h2 {
    color: azure;
}

.title2 {
    text-align: center;
    margin-top: 30px;
}



.ftcomp-row {
    display: flex;
    flex-wrap: wrap;

    justify-content: center;
    /* Adjust spacing between columns */
}

.ftcomp {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin: 15px;
    /* Spacing between cards */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    max-width: 100%;
    box-sizing: border-box;
}

.ftcomp:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.ftcomprow {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ftcompimg {
    width: 100%;
    overflow: hidden;
}

.ftcompimg img {
    width: 200px;
    height: 200px;
    display: block;
    transition: transform 0.3s;
}

.ftcompimg img:hover {
    transform: scale(1.05);
}

.ftcomptext {
    padding: 15px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin: 0;
    text-decoration: none;
    transition: color 0.3s;
}

.title:hover {
    color: #007bff;
    /* Adjust to match your brand color */
}

.cname {
    display: block;
    font-size: 14px;
    color: #666;
    margin-top: 5px;
    text-decoration: none;
}

.cname:hover {
    color: #333;
}

@media (max-width: 1200px) {
    .ftcomp-row {
        flex-direction: row;
    }

    .ftcomp {
        flex: 1 0 calc(25% - 30px);
        /* Adjust the width of each item */
        margin: 15px;
    }
}

@media (max-width: 992px) {
    .ftcomp {
        flex: 1 0 calc(33.333% - 30px);
        /* 3 items per row */
    }
}

@media (max-width: 768px) {
    .ftcomp {
        flex: 1 0 calc(50% - 30px);
        /* 2 items per row */
    }
}

@media (max-width: 576px) {
    .ftcomp {
        flex: 1 0 100%;
        /* 1 item per row */
    }
}


.industry-box-wrap {
    margin: 0 -12px;
    padding: 20px 30px;
    display: flex;
    flex-wrap: wrap;
}

.industry-box-wrap>* {
    margin: 0;
    padding: 12px;
    flex-basis: 25%;
    width: 25%;
}

.industry-box {
    padding: 12px;
    height: 100%;
    border: 1px solid #ddecfe;
    background: #fff;
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
}

.industry-box .d-flex {
    flex-wrap: nowrap;
    align-items: center;
}

.d-flex {
    display: flex;
}

.industry-icon {
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scale(3);
    opacity: 0.2;
    transition: 0.3s ease-in-out;
    transform-origin: bottom right;
}

.industry-icon img {
    width: 100%;
    transition: all .5s;
}

.industry-name a {
    color: #0a60cb;
}

.industry-list li {
    margin-bottom: 3px;
    padding-left: 17px;
    position: relative;
}

.industry-list li:before {
    position: absolute;
    content: "\f054";
    display: inline-block;
    top: 3px;
    left: 3px;
    font-family: "Font Awesome 5 Free";
    font-size: 10px;
    color: #999;
    font-weight: 600;
}

/* ======================= Responsive Media Query ============================= */


/* Media Queries for responsiveness */
@media (max-width: 1200px) {
    .industry-box-wrap>* {
        flex-basis: 33.33%;
        width: 33.33%;
    }
}

@media (max-width: 992px) {
    .industry-box-wrap>* {
        flex-basis: 50%;
        width: 50%;
    }
}

@media (max-width: 768px) {
    .industry-box-wrap>* {
        flex-basis: 100%;
        width: 100%;
    }
}

/* ============================= All Country CSS  ==================================== */
.countries-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    padding: 20px;
}

.country {
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.country .flag {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: white;
    margin: 12px 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.country .flag img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.country:hover .flag {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.country p {
    margin-top: 10px;
    font-weight: bold;
}

@media (max-width: 1200px) {
    .countries-container {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 1000px) {
    .countries-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 808px) {
    .countries-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 500px) {
    .countries-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 400px) {
    .countries-container {
        grid-template-columns: repeat(1, 1fr);
    }
}