/* b2b-page.css */

/* Container for the entire page */
.b2b-page {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

/* Left content area for displaying company details */
.content-left {
    width: 70%;
}

/* Style for each individual company card */
.company-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.company-card h3 {
    color: #333;
}

.company-card p {
    margin: 5px 0;
    font-size: 14px;
}



/* Sidebar on the right for filter options */
.sidebar-right {
    width: 30%;
    /* background-color: #007bff; */
    color: #000;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Styling for filter options */
.sidebar-right h3 {
    margin-bottom: 10px;
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
}

/* Example styling for filter options (you can customize this further) */
.filter-option {
    margin-bottom: 10px;
}

.filter-option label {
    display: block;
    margin-bottom: 5px;
}

.filter-option input[type="checkbox"] {
    margin-right: 5px;
}

.filter-option input[type="checkbox"]:checked+label {
    font-weight: bold;
}

/* Media query for tablet and desktop screens */
@media (min-width: 768px) {
    .b2b-page {
        flex-direction: row;
    }

    .content-left {
        width: 70%;
        margin-right: 20px;
        margin-bottom: 0;
    }

    .sidebar-right {
        width: 30%;
    }
}

/* CSS for mobile responsiveness */

/* Adjust layout for mobile view */
@media screen and (max-width: 768px) {
    .b2b-page {
        display: flex;
        flex-direction: column;
    }

    .content-left,
    .sidebar-right {
        width: 100%;
    }
}

/* Additional styling for mobile view if needed */
.forms-wrap {
    width: 100%;
    padding: 10px;
    margin: 11px 5px;
    border-radius: 5px;
    background: #f7f1f1;
    border: 1px solid #000;
}

/* Styling for Company Card */
.company-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.company-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.company-logo img {
    width: 100px;
    /* Adjust size as per your logo */
    height: 120px;
    margin-right: 20px;
    border-radius: 5px;
}

.company-details {
    flex: 1;
}

.company-details h3 {
    color: #333;
    margin-bottom: 10px;
}

.company-details p {
    margin: 5px 0;
    font-size: 14px;
}

.company-description {
    margin-top: 10px;
}

.company-description p {
    margin-bottom: 5px;
}

/* Styling for Sidebar Right (Forms Section) */
.sidebar-right {
    width: 30%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f7f1f1;
    margin-left: 20px;
    /* Adjust margin as per your layout */
}

.forms-wrap {
    width: 100%;
}

.form-title {
    margin-bottom: 10px;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    color: orange;
}

.form-group {
    margin-bottom: 10px;
}

.form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-submit {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.form-submit:hover {
    background-color: #0056b3;
}

.form-success {
    color: green;
    margin-top: 10px;
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #a9baa1;
    border-radius: .25rem;
}

.breadcrumb-item.active {
    color: #f28518;
    margin-top: 1px;
}

.imp-btns {
    display: flex;

    margin-top: 20px;
}



.main-btns {
    padding: 8px 15px;
    background-color: #f28518;
    color: #000;

}

.main-btns:hover {
    background-color: #df9c58;
    color: #000;
}

.main-btns {
    display: inline-block;
    margin: 10px;
    padding: 12px 24px;
    background-color: #de9759;
    color: #000;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.main-btns:hover {
    background-color: #f1be8a;
    border-color: #0056b3;
}

.button-link {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    width: 100%;
    height: 100%;
}

.button-link:hover {
    text-decoration: none;
    color: inherit;
}