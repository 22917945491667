.Premium-Header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 58px;
  position: relative;
}

.Premium-Header {
  background-color: #0c4c88;
  color: white;
  padding: 18px 20px;
}

.company_header {
  background: #fff;
  border: solid 1px #ddd;
  border-bottom: none;
}

.top_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.top_head .company_left {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.top_head .company_left .logo_img2 {
  line-height: 0;
  margin-right: 15px;
  border: 1px solid #eee;
  float: left;
  width: 70px;
  height: 70px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  font-size: 40px;
  font-weight: 300;
  color: #fff;
}

.top_head .company_left {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.swiper-container {
  width: 100%;
  overflow: hidden;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-content {
  text-align: center;
}

.welcome h1 {
  align-items: center;
  padding: 20px 10px;
}

.welcome {
  background-color: #f9f9f9;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1169px;
  margin: 0px auto;
  font-family: Arial, sans-serif;
}

.welcome h1 {
  color: #4a90e2;
  /* Blue color for the heading */
  font-size: 28px;
  /* Larger font size for the main heading */
  text-align: center;
  /* Center the heading */
  margin-bottom: 20px;
  /* Space below the heading */
}

.welcome h2 {
  color: #333;
  /* Darker color for subheadings */
  font-size: 22px;
  /* Subheading font size */
  margin-top: 20px;
  /* Space above subheadings */
}

.welcome p {
  color: #555;
  /* Gray color for paragraph text */
  line-height: 1.6;
  /* Better line spacing for readability */
  margin-bottom: 15px;
  /* Space between paragraphs */
}

.pro_inner {
  display: flex;
  /* Flexbox layout for horizontal alignment */
  background-color: #fff;
  /* White background for a clean look */
  border: 1px solid #e0e0e0;
  /* Light border for definition */
  border-radius: 8px;
  /* Slightly rounded corners */
  padding: 15px;
  /* Padding inside the container */
  margin-bottom: 20px;
  /* Space between list items */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for a professional look */
}

.pro_img_sec {
  width: 40%;
  /* Image section takes 40% of the width */
  display: flex;
  justify-content: center;
  /* Center the image horizontally */
  align-items: center;
  /* Center the image vertically */
  margin-right: 20px;
  /* Space between image and product info */
}

.pro_img_sec img {
  max-width: 100%;
  /* Ensure the image is responsive */
  height: auto;
  border-radius: 5px;
  /* Slightly rounded image corners */
}

.products_info {
  width: 60%;
  /* Product info section takes 60% of the width */
  display: flex;
  flex-direction: column;
  /* Arrange content vertically */
  /* justify-content: space-between; */
  /* Distribute space evenly */
}

.pro_title h3 {
  font-size: 20px;
  /* Font size for the product title */
  color: #333;
  /* Dark color for the title */
  margin-bottom: 10px;
}

.pro_title a {
  text-decoration: none;
  /* Remove underline from links */
  color: inherit;
  /* Inherit color from parent */
}

.pro_price {
  font-size: 18px;
  color: #e74c3c;
  /* Red color for price */
  margin-bottom: 15px;
}

.pro_det_sec ul {
  list-style-type: none;
  /* Remove default bullet points */
  padding: 0;
  /* Remove default padding */
  margin: 0;
}

.pro_det_sec ul li {
  font-size: 14px;
  margin-bottom: 5px;
  /* Space between list items */
  color: #555;
}

.pro_det_sec ul li span {
  font-weight: bold;
  /* Bold labels for attributes */
  color: #333;
}

.bt_sec_new {
  margin-top: 20px;
  /* Space above buttons */
}

.bt_sec_new a {
  display: inline-block;
  /* Ensure buttons are inline */
  padding: 10px 20px;
  /* Padding inside buttons */
  border-radius: 5px;
  /* Rounded corners for buttons */
  text-decoration: none;
  /* Remove underline */
  color: #fff;
  /* White text color */
  background-color: #3498db;
  /* Blue background for buttons */
  margin-right: 10px;
  /* Space between buttons */
  font-size: 14px;
  text-align: center;
  /* Center button text */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover */
}

.bt_sec_new a:hover {
  background-color: #2980b9;
  /* Darker blue on hover */
}

.about-us-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.intro {
  text-align: center;
  margin-bottom: 20px;
}

.intro h1 {
  font-size: 2em;
  color: #2c3e50;
}

.intro p {
  font-size: 1.2em;
  color: #7f8c8d;
}

.company-info {
  max-width: 800px;
  margin: 0 auto;
}

.company-info h2 {
  font-size: 1.8em;
  color: #3498db;
  margin-bottom: 10px;
}

.company-info h3 {
  font-size: 1.5em;
  color: #2ecc71;
  margin-top: 20px;
  margin-bottom: 10px;
}

.company-info p {
  margin-bottom: 20px;
}

.company-info p strong {
  color: #34495e;
}

.video-container {
  margin-top: 20px;
  text-align: center;
}

.video-container iframe {
  max-width: 100%;
  height: 400px;
  border: 0;
}
