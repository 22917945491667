* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul li {
    list-style: none;
}

a {
    text-decoration: none;
    color: rgb(21, 22, 23);
    font-size: 16px;
}

/* navbar */
.navbar {
    /* background-color: #a1d0eb; */
    background-color: #fff;
    padding: 23px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    min-height: 70px;
    margin-top: 30px;
    position: relative;
    z-index: 5;
}


/* logo  */
.logo {
    font-size: 30px;
    width: 20%;
    margin-right: 30px;

}


/* ul  */
.navMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 40px; */
}

.selection {
    display: flex;
    width: auto;
    flex-wrap: nowrap;
    height: 50px;
    outline: none;
    /* border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
    cursor: pointer;
    border: none;
    /* background-color: #e4d7d7; */
    background-color: #fff;
    background-color: #f7f1f1;
    border: 2px solid #000;
}

.magnifing-glass {
    margin-top: 0px !important;
    size: 31px !important;
    width: 24px !important;
    height: 24px !important;
    cursor: pointer !important;
    background: white !important;
    padding: 13px !important;
}

option {
    cursor: pointer;
}

.imp-section {
    margin-left: 30px;
}

/* media queries */

@media screen and (max-width: 780px) {

    /* hamburger */
    .hamburger {
        display: block;
        cursor: pointer;
    }

    .bar {
        background-color: #000;
        width: 20px;
        height: 3px;
        display: block;
        margin: 5px;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

    /* adding active class to the bars  */
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }


    /* navMenu */

    .navMenu {
        position: absolute;
        flex-direction: column;
        gap: 0;
        top: 145px;
        left: -100%;
        text-align: start;
        width: 100%;
        transition: 0.7s ease-in-out;
        background-color: rgb(235, 202, 141);
        z-index: 30;
        padding: 10px;
    }

    .navMenu.active {
        left: 0;

    }

    .navMenu li {
        margin: 16px 0;
    }
}


.activeLink a {
    /* text-decoration: underline; */
    text-decoration-style: solid;
    transition: 0.105s;
    position: relative;
}

/* .activeLink a {
    text-decoration: none;
    position: relative;
  } */

.activeLink a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    /* Adjust as needed */
    left: 0;
    width: 0;
    height: 2px;
    /* Thickness of the underline */
    background-color: #000;
    /* Color of the underline */
    transition: width 0.5s ease-out;
    /* Transition effect */
}

.activeLink a:hover::after,
.activeLink a:focus::after {
    /* Adjust as needed */
    width: 100%;
    /* Underline grows on hover */
}

/* Styling for the main input */
.mainInput {
    flex: 1;
    /* Take remaining space */
    width: 285px;
    height: 50px;
    border: 2px solid #000;
    padding-left: 5px;
    /* Gray border */
    /* border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; */
    /* Rounded corners */
    font-size: 16px;
    /* Font size */
    color: #495057;
    /* Text color */
    /* background-color: #e4d7d7; */
    /* background-color: #e4d7d7; */
    /* background-color: #fff; */
    /* background-color: #e4d7d7; */
    background-color: #f7f1f1;
    /* White background */
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    /* Smooth transition for border color and box-shadow */
}

/* Hover effect */
/* input:hover {
    border-color: #80bdff;

} */

/* Focus effect */
/* input:focus {
   
} */

.mainDashboard {
    display: flex;
    list-style: none;

}

/* styles.module.css */
.inputContainer {
    position: relative;
}

.mainInput {
    padding-right: 30px;
    /* cursor: pointer; */
    /* Adjust as needed to accommodate the icon */
}

.icon {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 40px;
    transform: translateY(-50%);
    pointer-events: none;
    cursor: pointer !important;
    color: orange;
    /* Ensures the icon doesn't interfere with input */
}

/* .searchIcon {
    width: 30px;

} */

.yourRequirement {
    background-color: orange;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
    height: auto;
    margin-left: 30px;
    padding: 13px;
    text-align: center;
    width: auto;

}

/* Navbar.module.css */
/* Navbar.module.css */

.userDropdown {
    position: relative;
    display: inline-block;
    /* margin-left: 30px; */
    margin-left: 8px;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 145px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* Add transition for opacity */
}

.userDropdown:hover .dropdownContent {
    display: block;
    opacity: 1;
    /* Change opacity on hover */
}

.dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdownContent a:hover {
    background-color: #f1f1f1;
}

/* Navbar.module.css */

/* Navbar.module.css */

@media screen and (max-width: 800px) {
    .userDropdown {
        order: -1;
        /* Move user dropdown to the beginning */
    }
}

@media screen and (max-width: 310px) {
    .userDropdown {
        order: 1;
        /* Move user dropdown to the top */
    }
}

@media screen and (max-width: 700px) {
    .logo img {
        margin-top: 18px;
    }
}

/* Navbar.module.css */

/* Navbar.module.css */