/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap"); */
/* @import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,300i,400,400i,600,600i,700,700i); */

/*=============== VARIABLES CSS ===============*/
:root {
    --header-height: 2.5rem;

    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    --black-color: hsl(220, 24%, 12%);
    --black-color-light: hsl(220, 24%, 15%);
    --black-color-lighten: hsl(220, 20%, 18%);
    --white-color: #fff;
    --body-color: hsl(220, 100%, 97%);

    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: "Montserrat", sans-serif;
    --normal-font-size: .938rem;

    /*========== Font weight ==========*/
    --font-regular: 400;
    --font-semi-bold: 600;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 20;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1024px) {
    :root {
        --normal-font-size: 1rem;
    }
}

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: var(--body-font);
    /* font-size: var(--normal-font-size); */
    background-color: var(--body-color);
}

ul {
    list-style: none;
    /* Color highlighting when pressed on mobile devices */
    /*-webkit-tap-highlight-color: transparent;*/
}

a {
    text-decoration: none;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
    max-width: 1120px;
    margin-inline: 1.5rem;
}

/*=============== HEADER ===============*/

.header {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    background-color: #f1f1f100 !important;
    color: #fff;
    margin-top: 32px;
    /* box-shadow: 0 2px 16px hsla(220, 32%, 8%, .3); */
    z-index: var(--z-fixed);
}


.header-scrolled {
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15s) !important;
}

/*=============== NAV ===============*/
.nav {
    height: 3.0rem;
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    /* display: flex; */
    /* flex-wrap: wrap; */
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav__logo,
.nav__burger,
.nav__close {
    color: black;
}

.nav__data {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo {
    display: inline-flex;
    align-items: center;
    column-gap: .25rem;
    font-weight: var(--font-semi-bold);
    /* Color highlighting when pressed on mobile devices */
    /*-webkit-tap-highlight-color: transparent;*/
}

.nav__logo i {
    font-weight: initial;
    font-size: 1.25rem;
}

.nav__toggle {
    position: absolute;
    width: 48px;
    height: 32px;
    right: 0;
}

.nav__burger,
.nav__close {
    position: absolute;
    width: max-content;
    height: max-content;
    inset: 0;
    margin: auto;
    font-size: 1.25rem;
    cursor: pointer;
    transition: opacity .1s, transform .4s;
}

.nav__close {
    opacity: 0;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1118px) {
    .nav__menu {
        position: absolute;
        left: 0;
        top: 2.5rem;
        width: 100%;
        height: calc(100vh - 3.5rem);
        overflow: auto;
        pointer-events: none;
        opacity: 0;
        transition: top .4s, opacity .3s;
    }

    .nav__menu::-webkit-scrollbar {
        width: 7px;
        max-height: 30px;
        color: grey;
    }

    .nav__list {
        background-color: white;
        color: red;
        padding-top: 1.5rem;
    }
}

.nav__link {

    background-color: #f1f1f100 !important;
    color: #fff !important;
    /* font-weight: var(--font-semi-bold); */
    padding: 1.25rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    font-weight: 400;
    font-size: 14px;
}

.nav__link2 {

    background-color: #ffff !important;
    /* font-weight: var(--font-semi-bold); */

    color: #6b797c !important;
}

@media only screen and (max-width: 1120px) {
    .nav__link {
        color: #626262 !important;
    }
}

.nav__link:hover {
    background-color: #fff;
    color: red;
}

/* Show menu */
.show-menu {
    opacity: 1;
    top: 2.8rem;
    pointer-events: initial;
}

/* Show icon */
.show-icon .nav__burger {
    opacity: 0;
    transform: rotate(90deg);
}

.show-icon .nav__close {
    opacity: 1;
    transform: rotate(90deg);
}

/*=============== DROPDOWN ===============*/
.dropdown__item {
    cursor: pointer;
}

.dropdown__arrow {
    font-size: 1.25rem;
    font-weight: initial;
    transition: transform .4s;
}

.dropdown__link,
.dropdown__sublink {
    padding: 1.25rem 1.25rem 1.25rem 2.5rem;
    color: black;
    background-color: #fff;
    display: flex;
    align-items: center;
    column-gap: .5rem;
    border-bottom: 1px solid black;
    /* font-weight: var(--font-semi-bold); */
    transition: background-color .3s;
    text-decoration: none;
}

.dropdown__link i,
.dropdown__sublink i {
    font-size: 1.25rem;
    font-weight: initial;
    text-decoration: none;
}


.dropdown__link:hover,
.dropdown__sublink:hover {
    background-color: #ffffff;
    color: red;
    text-decoration: none;
}

.dropdown__submenu {

    overflow: hidden;
    background-color: #fff;
    color: black;
    transition: max-height .4s ease-out;
    font-size: 14px;
}

.dropdown__menu {
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
    color: black;
    transition: max-height .4s ease-out;
    font-size: 14px;
}

/* Show dropdown menu & submenu */
.dropdown__item:hover .dropdown__menu,
.dropdown__subitem:hover>.dropdown__submenu {
    max-height: 300px;
    transition: max-height .4s ease-in;
}

/* Rotate dropdown icon */
.dropdown__item:hover .dropdown__arrow {
    transform: rotate(180deg);
}

/*=============== DROPDOWN SUBMENU ===============*/
.dropdown__add {
    margin-left: auto;
}

.dropdown__sublink {
    background-color: #fff;
}

.nav ul {
    padding-left: 0px;
}

/* .nav ul li ul {
 

} */

.nav ul li ul li {
    min-width: 200px;
    padding: 5px 4px;

}

.dropdown__link,
.dropdown__sublink {
    padding: 5px 10px !important;
    border-bottom: 1px solid #f0f0f0;

}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
    .container {
        margin-inline: 1rem;
    }

    .nav__link {
        padding-inline: 1rem;
    }
}

/* For large devices */
@media screen and (min-width: 1118px) {
    .container {
        margin-inline: auto;
    }

    .nav {
        height: calc(var(--header-height) + 2rem);
        display: flex;
        justify-content: space-between;
    }

    .nav__toggle {
        display: none;
    }

    .nav__list {
        height: 100%;
        display: flex;
        column-gap: 1.5rem;
    }

    .nav__link {
        height: 100%;
        padding: 0;
        justify-content: initial;
        column-gap: .75rem;
    }

    .nav__link:hover {
        background-color: transparent;
    }

    .dropdown__item,
    .dropdown__subitem {
        position: relative;
    }

    .dropdown__menu,
    .dropdown__submenu {
        max-height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;
        position: absolute;
        left: 0;
        top: 6rem;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s, top .3s;
    }

    /* =========================== Scroll bar with respect to y axis ======================= */

    /* width */
    .dropdown__menu::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    .dropdown__menu::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    .dropdown__menu::-webkit-scrollbar-thumb {
        background: #9a9494;
    }

    /* Handle on hover */
    .dropdown__menu::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .dropdown__link,
    .dropdown__sublink {
        padding-inline: 1rem 3.5rem;
        text-decoration: none;
    }

    .dropdown__subitem .dropdown__link {
        padding-inline: 1rem;
    }

    .dropdown__submenu {
        position: absolute;
        left: 100%;
        top: .5rem;
    }

    /* Show dropdown menu */
    .dropdown__item:hover .dropdown__menu {
        opacity: 1;
        top: 3.9rem;
        pointer-events: initial;
        transition: top .3s;
    }

    /* Show dropdown submenu */
    .dropdown__subitem:hover>.dropdown__submenu {
        opacity: 1;
        top: 0;
        pointer-events: initial;
        transition: top .3s;
    }
}



.topbar {
    background: #2a3646;
    min-height: 30px;

    /* display: flex; */
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 25;
    color: #fff;
}


/* .topbar img{
    height: 22px;
  } */
.topbar ul {
    list-style: none;
    justify-content: end;
    float: right;
    margin: 0 -100px;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    /* margin: auto 0; */
    /* margin-top: 6px; */
}

.topbar ul li a {
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    font-family: Montserrat, sans-serif;
}

/* a, a:visited, a:hover, a:active {
    color: inherit;
  } */
.topbar li {
    float: left;
    line-height: 34px;
    padding-right: 20px;
}

.hidden-xs:hover {
    text-decoration: underline;
    color: none;
}


.dropdown {
    min-width: 18em;
    position: relative;


}

.dropdown * {
    box-sizing: border-box;
}

.select {
    color: #fff;
    display: flex;
    justify-content: left;
    align-items: left;
    position: relative;
    cursor: pointer;

}

.select-clicked {

    box-shadow: 0 0 0 0.8 #26489a;
}

.caret {
    width: 0;
    height: 0;
    bottom: 0;
    left: 40px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid #fff;
    transition: 0.3s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}

.caret-rotate {
    transform: rotate(180deg);
}

.menu {
    list-style: none;
    padding: 1.2em 1.5em;
    background: #fff;
    color: black;
    border: 1px #ccc solid;
    box-shadow: 0 0.4 1em rgba(0 0 0 0.2);
    left: 50%;
    position: absolute;
    top: 2.1em;
    width: 80%;
    /* transform: translate(-50%); */
    transition: 0.2s;
    z-index: 19;
    display: none;
    opacity: 0;
}

.menu li {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: 0;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.menu li:hover {
    background-color: #f6f6f6;
    color: #2a3646;
    cursor: pointer;
    font-weight: 700;
    opacity: 1;
}

.menu-open {
    display: block;
    opacity: 1;
    margin-left: -100px;

}

/* Image Styles */
.img {
    height: 18px;
    vertical-align: middle;
}

/* Link Styles */
a {
    text-decoration: none;
    color: #fff;
}

.nav__logo .logo-light {
    width: 180px;
    margin-right: 20px;
    display: block;

}

.nav__logo .logo-dark {
    width: 180px;
    margin-right: 20px;
    display: none;

}

/* .nav__menu ul li ul{
  max-height: 300px;
  overflow-y: scroll;
} */


@media (max-width: 992px) {
    .topbar {
        background: #2a3646;
        min-height: 30px;
        width: 100%;
        position: fixed;
        align-items: center;
        justify-content: center;
    }

    .hidden-xs {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    /* .topbar {
        background: #2a3646;
        min-height: 30px;
        width: 100%;
        position: fixed;
        align-items: center;
        overflow-x: hidden;
        justify-content: center;
        top: 0px;
        z-index: 6;
        color: #fff;
    } */

    .topbar ul {
        float: left;
        padding-left: 80px;
    }


}

@media (max-width: 992px) {
    .topbar {
        background: #2a3646;
        min-height: 30px;
        width: 100%;
        /* z-index: 30; */
        position: fixed;
        align-items: center;
        justify-content: center;
    }

    .hidden-xs {
        display: none;
    }

}



@media (min-width: 768px) {
    .navbar-right {
        float: right !important;
        margin-right: -15px;
    }
}

@media screen and (max-width:1024px) {

    .discover {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 10px;
        color: #fff;
    }

}

/* +++++++++++++++++++++++++++================== Flight Page / Car Rental / Hotel / Tour ========================== */