.buylead-wrap {
    background: #f7f1f1;
    border: 1px solid #000;
}

.sect-head {
    margin-bottom: 17px;
}

.sect-head .title {
    font-size: 22px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
}

/* .buylead-scroll {
    height: 435px;
    padding: 10px 20px;
} */


.buylead {
    height: 435px;
    /* overflow: auto; */
    height: fit-content;
    padding: 10px 20px;
}

.buylead .item:not(:last-child) {
    border-bottom: 1px solid #eee;
    padding-bottom: 0px;
}


.buylead-box {
    display: table;
    width: 100%;
    padding: 7px 0;
    margin-left: -7px;
}

.buylead-box .title {
    color: #444;
    padding-right: 15px;
}

.buylead-box>* {
    display: table-cell;
    vertical-align: middle;
}

.buylead-box .title span {
    color: #505076;
    cursor: pointer;
    font-weight: 600;
}

.buylead-box .title span:hover {
    color: orange;
    outline: #444;
    cursor: pointer;
    font-weight: 600;
}

.buylead-box .location {
    width: 200px;
}

.buylead-country {
    display: flex;
    color: #555;
}

.buylead-country figure {
    width: 30px;
    line-height: 1px;
    margin-right: 7px;
    border: 1px solid #ddd;
}

.buylead-country figure img {
    line-height: 1px;
    max-width: 100%;
}

.buylead-country figure img {
    line-height: 1px;
    max-width: 100%;
}

.buylead .action {
    text-align: center;
    width: 120px;
}

.buylead-box>* {
    display: table-cell;
    vertical-align: middle;
}

.buylead .action .btn {
    background: orange;
    color: #000;
    display: inline-block;
    border-radius: 40px;
    padding: 5px 15px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.buylead .post-date {
    margin-top: 3px;
    color: #555;
    font-size: 13px;
}


@media screen and (max-width: 466px) {
    .buylead-country {
        display: none;

    }
}



.leads-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.box-round {
    border-radius: 10px;
}

.buylead-wrap {
    width: 50%;
}

.form-wrap {
    width: 50%;
    padding: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-input[type="text"],
.form-input[type="tel"],
.form-input[type="email"],
.form-input[type="checkbox"] {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}


button[type="submit"]:hover {
    background-color: #0c580c;
}

.leads-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.box-round {
    border-radius: 10px;
}

.buylead-wrap {
    width: 100%;
    max-width: 48%;
    margin: 10px 20px;

}

.form-wrap {
    width: 100%;
    max-width: 48%;
    margin: 11px 5px;
    border-radius: 5px;
    background: #f7f1f1;
    border: 1px solid #000;
}

.form-group {
    margin-bottom: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    text-align: left;
    flex-direction: column;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-input[type="text"],
.form-input[type="tel"],
.form-input[type="email"],
.form-check[type="checkbox"] {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}


button[type="submit"] {
    background-color: orange;
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #f0a56e;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
    .leads-container {
        flex-direction: column;
        /* Stack the sections vertically on smaller screens */
    }

    .box-round {
        margin-bottom: 20px;
        /* Add some space between the sections */
    }

    .buylead-wrap,
    .form-wrap {
        max-width: 100%;
        max-width: 100%;
        margin-left: 0px;

        /* Make each section take up 100% of the screen width on smaller screens */
    }
}



select {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

/* Optional: Style for the dropdown arrow */


/* Optional: Style for the dropdown options */
select option {
    background-color: #fff;
    color: #000;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    select {
        width: calc(100% - 38px);
        /* Adjust width to fit smaller screens */
        padding-right: 45px;
        /* Adjust padding for arrow */
    }

    select::-ms-expand {
        display: none;
        /* Hide the default arrow in IE */
    }
}

@media screen and (max-width: 480px) {
    select {
        width: 100%;
        /* Full width for screens smaller than 480px */
    }
}