.mb-2 {
  margin-bottom: 0.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kcYKtz .servHeading {
  color: rgb(8, 47, 74);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.625px;
  margin: 0px;
}

.kcYKtz .col-md-2.custom-width:nth-child(2n) {
  padding-right: 8px;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

@media (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
}

@media screen and (min-width: 768px) {
  .HnYQ {
    height: 100%;
  }
}

.HnYQ {
  background: rgb(255, 255, 255);
  border: 1.3px solid rgb(210, 218, 224);
  box-shadow: rgb(187, 194, 205) 3px 31px 40px -42px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  white-space: normal;
  height: 100%;
}

.HnYQ .card {
  height: 100%;
}

.justify-content-between {
  justify-content: space-between !important;
}

.flex-column {
  flex-direction: column !important;
}

.d-flex {
  display: flex !important;
}

.HnYQ .img_container {
  width: 100%;
  position: relative;
}

.HnYQ .mobile {
  display: block;
}

.HnYQ .img_container a {
  display: block;
  text-align: center;
}

.HnYQ a {
  text-decoration: none;
}

/* .card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    font-size: 20px;
    color: #333;
} */

.swiper-container {
  padding-bottom: 20px;
}

.swiper-pagination {
  bottom: 10px;
}

.swiper-pagination-bullet {
  background-color: #007bff;
}

/* 
.card {
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
} */

.card:hover {
  transform: translateY(-5px);
}

.card img {
  width: 300px;
  height: 200px;
  border-radius: 50%;
  /* border-top-left-radius: 8px;
    border-top-right-radius: 8px; */
}

.card-content {
  padding: 20px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-description {
  font-size: 14px;
  color: #666;
}

/* allcat.css */

.mySwiper {
  margin: 20px auto;
}

.card {
  /* background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card img {
  width: 100%;
  height: 135px;
  object-fit: cover;
  /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

.card-content {
  padding: 15px;
}

.card p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

@media screen and (max-width: 768px) {
  .card {
    max-width: 100%;
  }
}

.itdevice {
  max-width: 1200px;
  padding: 10px 20px;
}

.itimg {
  width: auto;
  height: 150px;
}

/* Define default styles */
.itdevice {
  text-align: center;
  /* Center the image */
}

.itimg {
  max-width: 100%;
  /* Ensure the image does not exceed its container's width */
  height: auto;
  /* Maintain aspect ratio */
}

.itdevice-container {
  display: flex;
  justify-content: space-between;
  /* Adjusts the space between the two banners */
  width: 100%;
}

.itdevice {
  flex: 1;
  /* Each banner takes up 50% of the container's width */
  display: flex;
  justify-content: center;
  /* Centers the content horizontally */
  align-items: center;
  /* Centers the content vertically */
  box-sizing: border-box;
  padding: 10px;
  /* Adds some padding around the banners */
}

.itimg {
  width: 100%;
  /* Ensures the image scales properly */
  height: 115px;
  /* Maintains the aspect ratio */
}

/* Media query for screen sizes less than 768px */
@media screen and (max-width: 768px) {
  .itdevice-container {
    flex-direction: column;
    /* Stacks the items in a column */
  }

  .itdevice {
    width: 100%;
    /* Each item takes up 100% of the container's width */
    margin-bottom: 10px;
    /* Adds some space between the rows */
  }

  .itdevice:last-child {
    margin-bottom: 0;
    /* Removes bottom margin from the last item */
  }
}

/* content css  */
/* GlobalB2BMart.css */
.market {
  font-family: "Arial", sans-serif;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.market-heading {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 2em;
  border-bottom: 2px solid #0056b3;
  padding-bottom: 10px;
}

.intro,
.b2b-definition,
.features,
.benefits,
.why-choose {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sub-heading {
  color: #0056b3;
  margin-bottom: 10px;
  font-size: 1.5em;
}

.reasons-list {
  /* list-style-type: disc; */
  /* margin-left: 20px; */
  text-align: left;
}

.footer {
  text-align: center;
  margin-top: 30px;
  font-size: 0.9em;
  color: #555;
}
