.registration-container {
    display: flex;
    height: 105vh;
    width: 100%;
    background-image: url('../../public/assets/renameres.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px;
    /* Adjust padding as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background: linear-gradient(135deg, #e7d0b9, #4a3d3d, #d88027); */
}

.login-container {
    width: 50%;
    padding: 40px;
    /* background: #f7f1f1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.regi-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}

.login-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgba(255, 255, 255, 0.1); */
    /* background: #f7f1f1; */
}

.login-image img {
    width: 100%;
    height: 100%;
}


.form-group {
    margin-bottom: 1px;
}

label {
    display: block;
    margin-bottom: 0px;
}

.reb-text[type="text"],
.reb-email[type="email"],
.reb-password[type="password"],
.reb-number[type="number"],
.reb-req[type="text"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.buyer-submit {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}


.glass-container {
    background: rgba(255, 255, 255, 0.1);
    /* Lightly colored background */
    backdrop-filter: blur(10px);
    /* Blur the background behind the form */
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    /* Soft shadow */
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* Light border */
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin-bottom: 100px;
}

.login-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    /* background: #f7f1f1; */
}

@media (max-width: 766px) {
    .registration-container {
        flex-direction: column;
    }

    .regi-container {
        width: 100%;
        padding: 20px;
    }

    .login-image {
        display: none;
        /* Hide the image on small screens */
    }
}

@media (max-width: 768px) {

    .regi-container,
    .login-image {
        width: 100%;
        padding: 20px;
    }

    .regi-container {
        flex-direction: column;
    }

}

.form-group label {
    display: block;
    margin-bottom: -2px !important;
    font-weight: bold;
    color: rgb(46, 89, 245) !important;
}

/* .form-group {
    margin-bottom: 5px !important;
} */

.PhoneInput {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}