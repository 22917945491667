/* .testimonial-container {
    padding: 20px;
}

.testimonial-slider {
    margin-top: 20px;
}

.testimonial-box {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out
}

.testimonial-box:hover {
    transform: translateX(-5px);
    cursor: pointer;
}

.testimonial-review {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
}

.testimonial-name {
    font-weight: bold;
}

.testimonial-position {
    color: #888;
    font-style: italic;
}

.swiper-pagination-bullet {
    background-color: orange;
    outline: black;
    size: 44px;
    border-radius: 50%;
    border: 5px solid orange;
}
 */

/* .swiper-pagination-bullet {
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.5);
} */
/* .testimonial-container {
  padding: 20px;
  font-family: "Arial", sans-serif;
}

.testimonial-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.mySwiper {
  margin-bottom: 30px;
}

.testimonial-box {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonial-review {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.testimonial-name {
  font-weight: bold;
  color: #333;
}

.testimonial-position {
  font-size: 0.9rem;
  color: #777;
}

.youtube-videos {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
  flex-wrap: wrap;
}

.video-container iframe {
  width: 300px;
  height: 170px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.client-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.client-box {
  text-align: center;
  padding: 10px;
}

.client-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #ddd;
}

.client-name {
  font-weight: bold;
  color: #333;
} */
/* Center the container and make it responsive */
.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
}

/* .testimonial-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
} */
.testimonial-title {
  font-size: 2rem; /* Initial font size */
  font-weight: bold;
  margin-bottom: 20px;
  color: #333; /* Initial color */
  animation: titleAnimation 3s ease-in-out infinite; /* Apply animation */
}

@keyframes titleAnimation {
  0% {
    font-size: 2rem;
    color: #333; /* Initial color */
  }
  25% {
    font-size: 3rem; /* Increased font size */
    color: #ff5733; /* Color changes to a reddish tone */
  }
  50% {
    font-size: 2rem; /* Original font size */
    color: #33c3ff; /* Color changes to a light blue */
  }
  75% {
    font-size: 2.5rem; /* Slightly increased font size */
    color: #28a745; /* Color changes to a greenish tone */
  }
  100% {
    font-size: 2rem;
    color: #333; /* Return to the original color */
  }
}

/* Swiper styles */
.mySwiper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.video-thumbnail {
  width: 300px;
  height: 200px;
  margin-top: 30px;
}
/* Video container with dynamic border */
.video-container {
  display: flex;
  background-color: #f7e4db;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 5px solid transparent; /* Default border */
  border-radius: 10px;
  transition: border-color 0.5s ease-in-out;
}

/* Add specific colors to each video */
.video-0 {
  /* border-color: #ff5733; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 7px solid transparent;
  animation: borderColorChange 6s linear infinite;
}

/* Add specific colors to each video */
.video-0,
.video-1,
.video-2 {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative; /* For positioning the text inside */
}

/* Border animation */
.video-0 {
  border: 7px solid transparent;
  animation: borderColorChange 6s linear infinite;
}

.video-1 {
  border: 10px solid transparent;
  animation: borderColorChange 6s linear infinite;
}

.video-2 {
  border: 7px solid transparent;
  animation: borderColorChange 6s linear infinite;
}

/* Animation for border color change */
@keyframes borderColorChange {
  0% {
    border-color: transparent;
  }
  10% {
    border-color: #67eee3;
  }
  20% {
    border-color: #19dd6b;
  }
  30% {
    border-color: #19a6dd;
  }
  40% {
    border-color: #2710f3;
  }
  50% {
    border-color: #ff5733; /* Example color */
  }
  60% {
    border-color: #ff33ee; /* Example color */
  }
  70% {
    border-color: #ff33a3; /* Example color */
  }
  80% {
    border-color: #fffb19; /* Example color */
  }
  90% {
    border-color: #f0a73a; /* Example color */
  }
  100% {
    border-color: #e25252;
  }
}

/* Moving feedback text */
.video-feedback-text {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px; /* Adjust size */
  font-weight: bold; /* Bold text */
  color: rgb(235, 147, 34); /* Text color */
  white-space: nowrap; /* Prevent text from wrapping */
  animation: textMove 5s linear infinite; /* Continuous animation */
}

@keyframes textMove {
  0% {
    transform: translate(-50%, -50%) translateX(-100%); /* Start off-screen */
  }
  50% {
    transform: translate(-50%, -50%) translateX(100%); /* Move across the screen */
  }
  100% {
    transform: translate(-50%, -50%) translateX(-100%); /* Loop the animation */
  }
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .video-frame {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .testimonial-title {
    font-size: 1.5rem;
  }
}
