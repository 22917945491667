/* .main-box {
    display: flex;
}

.main-box aside {
    width: 275px;
}

.flt-head {
    font-size: 16px;
    font-weight: 600;
    color: #024189;
    border-bottom: 2px solid #024189;
    border-radius: 5px 5px 0 0;
}

.flt-box.bdrt-0 {
    border-radius: 0 0 5px 5px;
}

.bdrt-0 {
    border-top: 0px;
}

.flt-box {
    border: 1px solid #e4e4e4;
    background: #fff;
    padding: 12px 15px;
    margin-bottom: 13px;
    border-radius: 5px;
}

.flt-title {
    font-size: 15px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
}

.flt-title svg {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
}

.flt-content {
    padding-top: 10px;
}

.flt-list {
    font-size: 14px;
    max-height: 162px;
}

.flt-list li:not(:last-child) {
    margin-bottom: 8px;
}

.flt-list a {
    color: #666;
}

.cust-scroll {
    overflow: auto;
}

.flt-box {
    border: 1px solid #e4e4e4;
    background: #fff;
    padding: 12px 15px;
    margin-bottom: 13px;
    border-radius: 5px;
}

.flt-title {
    font-size: 15px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
}


.flt-title svg {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
}

.flt-content {
    padding-top: 10px;
}

.flt-search {
    position: relative;
    margin-bottom: 10px;
    border: none;
    padding: 0;
}

.flt-search input {
    border: 1px solid #ddd;
    font-size: 13px;
    padding: 7px 15px 7px 30px;
    width: 100%;
    border-radius: 3px;
}

.flt-search svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #999;
}

.flt-list {
    font-size: 14px;
    max-height: 162px;
}

.flt-list li:not(:last-child) {
    margin-bottom: 8px;
}

.main-box main {
    width: calc(100% - 275px);
    padding-left: 33px;
}

.section {
    margin-bottom: 20px;
}

.sect-title {
    font-size: 16px;
    font-weight: 600;
    color: #091e42;
    margin-bottom: 16px;
}

.horprd {
    display: flex;
    flex-wrap: wrap;
}

.expcatg .item {
    width: 25%;
    position: relative;
    padding: 1px;
}

.horprd a {
    display: block;
}

.text-center {
    text-align: center;
}

.text-right,
.text-end {
    text-align: right;
} */
/* Reset some basic styles */
body,
html,
ul,
li,
p,
figure,
a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

/* Container for main and aside */
.main-box {
  display: flex;
  padding: 20px;
}

/* Aside styles */
aside {
  width: 20%;
  padding-right: 20px;
}

.flt-box-wrap {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.flt-box {
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.flt-head {
  background-color: #007bff;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.flt-title {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

/* .flt-content {
    display: none;
    margin-top: 10px;
} */

.flt-search {
  position: relative;
  margin-bottom: 10px;
}

.flt-search input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.flt-search svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ddd;
}

.flt-list {
  max-height: 150px;
  overflow-y: auto;
}

.flt-list a {
  display: block;
  padding: 5px;
  color: #007bff;
  transition: background-color 0.3s;
}

.flt-list a:hover {
  background-color: #007bff;
  color: #fff;
}

/* Main styles */
main {
  width: 80%;
}

.section {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.sect-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.horprd {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.horprd .item {
  width: calc(25% - 15px);
  margin-bottom: 20px;
}

.horprd-box {
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background: #fff;
  transition: box-shadow 0.3s, transform 0.3s;
}

.horprd-box:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.horprd-box figure {
  margin-bottom: 10px;
}

.horprd-box img {
  max-width: 100%;
  height: auto;
}

.horprd-box .title {
  font-size: 14px;
  color: #333;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .main-box {
    flex-direction: column;
  }

  aside {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  main {
    width: 100%;
  }

  .horprd .item {
    width: calc(50% - 15px);
  }
}

@media screen and (max-width: 480px) {
  .horprd .item {
    width: 100%;
  }
}

.classified-wrap {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.classified {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 270px;
}

.prd-info {
  text-align: center;
}

.cinfo {
  margin-top: 10px;
}

.cbox {
  display: flex;
}

.cbox figure {
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

.cmp-year {
  background-color: #f0ad4e;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
}

.cboxr {
  flex: 1;
}

.cboxr .title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.cboxr .cloc {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #777;
}

.cboxr .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -110px;
  opacity: 0;
  transition: opacity 0.3s;
}

.cboxr .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.rating-wrap {
  display: flex;
  align-items: center;
}

.rtbox {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-right: 5px;
}

.crate {
  width: 50px;
  height: 10px;
  background-color: #ffd700;
  position: relative;
}

.crate::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--_score);
  height: 100%;
  background-color: #4caf50;
}

.rate-text {
  font-size: 12px;
  color: #777;
  margin-left: 5px;
}

.caddit {
  margin-top: 10px;
}

.item {
  margin-bottom: 10px;
}

.ca-box {
  display: flex;
  align-items: center;
}

.ca-box p {
  margin: 0;
}

.l3icon {
  margin-right: 5px;
}

.caction {
  margin-top: 10px;
  text-align: center;
}

.caction {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  background: #d65858;
}

.caction p:hover {
  background: #ea8c8c;
  color: #333;
}

.classfied-wrap {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 50px;
}
.onion-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
}

.onion-title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.onion-description {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 40px;
}

/* Section Styles */
.onion-quality-section,
.onion-dehydrated-section,
.onion-white-section,
.onion-spices-section,
.onion-why-choose-section,
.onion-contact-section {
  margin-bottom: 40px;
}

.onion-heading {
  /* font-size: 2rem; */
  text-align: center;
  color: #ff9b18;
  margin-bottom: 15px;
}

.onion-info {
  font-size: 0.9rem;
  text-align: center;
  color: #555;
  line-height: 1.8;
}

/* List Styles */
.onion-list {
  list-style-type: none;
  padding: 0;
}

.onion-list-item {
  font-size: 0.9rem;
  text-align: center;
  color: #555;
  margin-bottom: 10px;
}

.onion-support-container {
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
}

.onion-support-title {
  /* font-size: 2.5rem; */
  color: #ff9b18;
  text-align: center;
  margin-bottom: 20px;
}

.onion-support-description {
  font-size: 0.9rem;
  line-height: 1.5;

  color: #555;
  text-align: center;
  margin-bottom: 40px;
}

/* Section Styles */
.onion-fresh-produce-section,
.onion-digital-marketing-section,
.onion-international-trading-section,
.onion-quality-ensurance-section {
  margin-bottom: 40px;
}

.onion-support-heading {
  /* font-size: 2rem; */
  text-align: center;
  color: #ff9b18;
  margin-bottom: 15px;
}

.onion-support-info {
  font-size: 0.9rem;
  color: #555;
  text-align: center;
  line-height: 1.8;
}

/* Responsive List Style */
.onion-support-list {
  list-style-type: none;
  padding: 0;
}

.onion-support-list-item {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
}

.section-content {
  text-align: left !important;
}
