.containersx {
    display: flex;
    flex-wrap: wrap;
}

button {
    padding: '5px 10px';
    color: '#000';
    background: '#f7f1f1',
}

.buyers {
    flex: 1 1 50%;
    padding: 10px;
}

.buyer-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
}

.buyer-card h2 {
    margin-top: 0;
}

.sidebar {
    flex: 0 1 20%;
    padding: 10px;
    background: #f1f1f1;
    border-left: 1px solid #ddd;
}

.browser {
    flex: 1 1 20%;
    padding: 10px;
}

.sidebar h3 {
    margin-top: 0;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar li {
    margin: 5px 0;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .buyers,
    .sidebar {
        flex: 1 1 100%;
    }
}

/* OnionBuy.css */

.detail-btn {
    padding: 5px 10px;
    background: #f7f1f1;
    color: orange;
    border-radius: 3px;
    cursor: pointer;
    border: none;
    outline: none;
}

.buyer-card p strong {
    margin-right: 5px;
}

.buyer-card p {
    margin: 5px 0;
}

/* OnionBuy.css */

.popup {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    z-index: 1000;
}

.premium-button-container {
    margin-top: 20px;
}

.premium-button {
    padding: 10px 20px;
    background-color: orange;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.premium-button:hover {
    background-color: darkorange;
}

.detailing-btn {
    padding: 10px 20px;
    /* Removed single quotes around padding values */
    color: #fff;
    /* Text color */
    background-color: #2cce2c;
    /* Background color */
    border: none;
    /* No border */
    border-radius: 3px;
    /* Border radius */
    cursor: pointer;
    /* Cursor style */
}

.regis-btn {
    padding: 10px 20px;
    /* Removed single quotes around padding values */
    color: #fff;
    /* Text color */
    background-color: #1449e6;
    /* Background color */
    border: none;
    /* No border */
    border-radius: 3px;
    /* Border radius */
    cursor: pointer;
}

.regis-btn:hover {
    color: #4e2020;
    /* Text color */
    background-color: #5ec0f1;
}

.detailing-btn:hover {
    color: #4e2020;
    /* Text color */
    background-color: #79f379;
}

.btns-imp {
    display: flex;
    gap: 10px;
}